.register {
    background: url("../../assets/images/bg.jpg");
    background-size: cover;
    height: 100vh;
}



.mod-reg {
    width: 480px;
    /*height: 480px;*/
    padding: 10px 30px;
    background: #272727aa;
    border-radius: 12px;
    overflow: hidden;
}

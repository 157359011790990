.chart-drawer .ant-drawer-wrapper-body,.chart-drawer .ant-drawer-content{
    background-color: #57575750;

}
.chart-drawer .ant-drawer-body{
    padding:0px 12px 24px 12px;
}
.chart-drawer .ant-drawer-header{
    background-color: #27282750;
    border-bottom: none;
    padding: 5px 10px;

}

.chart-drawer .ant-drawer-title{
    color:beige;
}

.chart-drawer .ant-drawer-close{
    color: #ff0000;
    padding:4px 4px 0px 0px;
    font-weight: bold;
}

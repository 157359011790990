@import "~antd/dist/antd.dark";

body {
    overflow: hidden;
    margin: 0;
    color: white !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar-track-piece {
    width: 8px;
    background-color: rgba(0,0,0, 0.7);
    border-radius: 8px;
}

::-webkit-scrollbar {
    width: 8px;
    height: 6px;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    height: 6px;
    background: #625840;
    border-radius: 8px;
    cursor: pointer;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #625840;
}

::-webkit-scrollbar-corner {
    background: rgba(0,0,0, 0.7);
}

::-webkit-scrollbar-thumb:hover {
    background: #796c4f;
}

::-webkit-scrollbar-thumb:active {
    background: #958867;
}



/* Thin Scrollbar */
:root{
  scrollbar-color: #796c4f rgb(0,0,0) !important;
  scrollbar-width: thin !important;
}

.cesium-animation-shuttleRingG, .cesium-animation-knobOuter {
    display: none;
}

@primary-color: #1ba784;
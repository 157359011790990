
 .timeline {
	 display: table;
	 width: 100%;
	 padding-top: 3px;
	 padding-bottom: 2px;
	 padding-right: 2px;
	 font-family: "Open Sans", sans-serif;
}
 .text-row {
	 font-size: 32px !important;
	 background: rgba(255, 25, 255, 0) !important;
	 display: block;
	 color: #33FF00;
	 font-size: 0.9rem;
	 position: absolute;
	 bottom: 30px;
	 left: 0px;
	 background: #3f4854;
	 padding: 3px 5px;
}
 .text-cell {
	 display: table-cell;
}
 .time {
	 padding-left: 15px;
}
 .controlsRow {
	 display: table-row;
}
 .style-selector {
	 font-size: 0.9rem;
}
 .style-selector label {
	 margin-right: 5px;
}
 .title {
	 display: block;
	 margin-top: 10px;
	 margin-bottom: 5px;
	 font-weight: bold;
}
 .field {
	 border-style: solid;
	 border-width: 1px;
	 border-color: #ddd;
	 border-radius: 2px;
	 width: 100%;
	 color: black;
}
 .timelineDatePicker {
	 color: #fff;
	 display: table-cell;
	 width: 30px;
	 height: 30px;
}
 .datePicker {
	 width: 260px;
	 height: 300px;
	 border: 1px solid #888;
	 border-radius: 5px;
	 padding: 5px;
	 position: absolute;
	 left: 10px;
	 bottom: 70px;
	 background: #3f4854;
	 z-index: 100;
}
 .datePicker.openBelow {
	 top: 40px;
	 left: -190px;
}
 .dayPicker {
	 text-align: center;
	 margin-top: -10px;
}
 .backbutton, .backbtn {
	 background-color: transparent;
	 margin: 0;
	 border: 0;
	 padding: 0;
	 box-sizing: border-box;
	 font-weight: 400;
	 box-shadow: none;
	 text-align: left;
	 cursor: pointer;
	 line-height: 20px;
	 text-decoration: none;
	 font-size: 0.85rem;
	 font-family: "Open Sans", sans-serif;
     background: transparent;
     margin: 0;
	 display: inline-block;
	 z-index: 99;
	 position: relative;
}
 .backbutton svg, .backbtn svg {
	 height: 20px;
	 width: 25px;
	 fill: #fff;
	 display: inline-block;
	 vertical-align: bottom;
}
 .backbutton[disabled], .backbtn[disabled], .backbutton:disabled, .backbtn:disabled {
	 opacity: 0.1;
}
 .backbtn {
	 padding-bottom: 5px;
	 padding-right: 5px;
}
 .togglebutton {
	 background: transparent;
	 padding: 0px 6px;
	 border: 0;
	 height: 30px;
	 width: 30px;
	 margin-right: 5px;
	 display: inline-block;
}
 .togglebutton svg {
	 width: 18px;
	 height: 18px;
	 float: left;
	 fill: #fff;
}
 .grid {
	 display: block;
	 width: 100%;
	 height: 100%;
	 margin: 0 auto;
	 color: #fff;
	 padding: 0px 5px;
	 border-radius: 3px;
	 margin-top: -20px;
}
 .gridBody {
	 height: calc(100% - 30px);
	 overflow: auto;
}

 .gridRow:hover {
	 background: rgba(255, 255, 255, 0.15);
	 cursor: pointer;
}
 .inactiveGridRow:hover {
	 background: transparent;
	 cursor: default;
}
 .gridLabel {
	 float: left;
	 display: inline-block;
	 width: 35px;
	 font-size: 10px;
	 padding-left: 3px;
}
 .gridRowInner12, .gridRowInner31 {
	 display: table-row;
	 padding: 3px;
	 border-radius: 3px;
}
 .gridRowInner12 span, .gridRowInner31 span {
	 display: inline-block;
	 height: 10px;
	 width: 2px;
	 background: rgba(255, 255, 255, 0.15);
	 margin-top: 1px;
}
 .gridRowInner12 span {
	 margin-right: 11px;
}
 .gridRowInner31 span {
	 margin-right: 3px;
}
 .activeGrid {
	 opacity: 0.9;
	 background: #519ac2 !important;
}
 .gridHeading {
	 text-align: center;
	 color: #fff;
	 font-size: 12px;
}
 .summary {
	 font-size: 10px;
}
 .centuryBtn, .dateBtn {
     background-color: transparent;
     margin: 0;
     border: 0;
     padding: 0;
     box-sizing: border-box;
     font-weight: 400;
     box-shadow: none;
     text-align: left;
     cursor: pointer;
     line-height: 20px;
     text-decoration: none;
     font-size: 0.85rem;
     font-family: "Open Sans", sans-serif;

     display: block;
     background: #519ac2;
     font-family: "Open Sans", sans-serif;
     color: #fff;
     text-align: center;
     width: 100%;
     box-sizing: border-box;
     border-radius: 2px;
     padding: 10px;
	 width: calc(100% - 20px);
	 margin: 5px;
}
 .centuryBtn {
	 display: inline-block;
	 width: 40%;
}
 
/*
 * Created at 2020/7/12 下午5:19 by lhq
 */

.layer-info-modal .ant-modal-content{
    overflow-y: auto !important;
    max-height: 800px;
    background-color: transparent;
}

.layer-info-modal .ant-modal-header{
    /*background-color: #4395FFcc;*/
    padding: 8px 24px;
    /*padding-bottom: 16px;*/

}

.layer-info-modal .ant-modal-close-x{

    height: 28px;
    line-height: 38px;
}



.ant-tree-switcher.ant-tree-switcher-noop{
      /*display:none;*/
    background-color: transparent!important;
    color: transparent!important;
}


.small-node{
    font-size: 16px!important;
}

.big-node{
    font-size: 20px!important;
    font-weight: bold;
    text-decoration: underline;
    /*color: #33FF00;*/
}

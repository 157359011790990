.cesiumTimeline {
	 display: table-cell;
	 height: 19px;
	 vertical-align: bottom;
}
.cesium-timeline-needle,.cesium-timeline-trackContainer,.cesium-timeline-ruler {
	 display: none !important;
}
.cesium-timeline-main {
	width: calc(100% - 29px);
	 position: relative;
	 left: 0;
	 bottom: 0;
	 overflow: hidden;
	 border: solid 1px #888;
}
.cesium-timeline-trackContainer {
	 width: 100%;
	 overflow: auto;
	 border-top: solid 1px #888;
	 position: relative;
	 top: 0;
	 left: 0;
}
.cesium-timeline-tracks {
	 position: absolute;
	 top: 0;
	 left: 0;
	 width: 100%;
}
.cesium-timeline-needle {
	 position: absolute;
	 left: 0;
	 top: 1.7em;
	 bottom: 0;
	 width: 1px;
	 background: #f00;
}
.cesium-timeline-bar {
	 position: relative;
	 left: 0;
	 top: 0;
	 overflow: hidden;
	 cursor: pointer;
	 width: 100%;
	 height: 1.7em;
	 background-color: #fafafa;
	 background: rgba(32, 32, 32, 0.8);
	 background: -moz-linear-gradient(top, rgba(116, 117, 119, 0.8) 0%, rgba(58, 68, 82, 0.8) 11%, rgba(46, 50, 56, 0.8) 46%, rgba(53, 53, 53, 0.8) 81%, rgba(53, 53, 53, 0.8) 100%);
	/* FF3.6+ */
	 background: -webkit-linear-gradient(top, rgba(116, 117, 119, 0.8) 0%, rgba(58, 68, 82, 0.8) 11%, rgba(46, 50, 56, 0.8) 46%, rgba(53, 53, 53, 0.8) 81%, rgba(53, 53, 53, 0.8) 100%);
	/* Chrome10+,Safari5.1+ */
	 background: linear-gradient(to bottom, rgba(116, 117, 119, 0.8) 0%, rgba(58, 68, 82, 0.8) 11%, rgba(46, 50, 56, 0.8) 46%, rgba(53, 53, 53, 0.8) 81%, rgba(53, 53, 53, 0.8) 100%);
	/* W3C */
}
.cesium-timeline-ruler {
	/* NOTE: The label and the ruler must use the same font/size */
	 visibility: hidden;
	 white-space: nowrap;
	 font-size: 80%;
	 z-index: -200;
}
.cesium-timeline-highlight {
	 position: absolute;
	 bottom: 0;
	 left: 0;
	 background: #08f;
}
.cesium-timeline-ticLabel {
	 position: absolute;
	 top: 0;
	 left: 0;
	 white-space: nowrap;
	 font-size: 80%;
	 color: #eee;
}
.cesium-timeline-ticMain {
	 position: absolute;
	 bottom: 0;
	 left: 0;
	 width: 1px;
	 height: 50%;
	 background: #eee;
}
.cesium-timeline-ticSub {
	 position: absolute;
	 bottom: 0;
	 left: 0;
	 width: 1px;
	 height: 33%;
	 background: #aaa;
}
.cesium-timeline-ticTiny {
	 position: absolute;
	 bottom: 0;
	 left: 0;
	 width: 1px;
	 height: 25%;
	 background: #888;
}
.cesium-timeline-icon16 {
	 display: block;
	 position: absolute;
	 width: 16px;
	 height: 16px;
	 background-image: url("../../../assets/images/TimelineIcons.png");
	 background-repeat: no-repeat;
}
 
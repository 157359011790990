/*
 * Created at 2021/12/31 下午5:18 by lhq
 */

div.login-box {

    padding: 20px 50px;
    position: absolute;
    top: 20%;
    left: calc(50% - 200px);
    background-color: #1f1f1f77;
}


div.login-logo {
    background: url("../../assets/images/titlebar.png");
    width: 100%;
    background-size: cover;
    height: 60px;
    margin-bottom: 20px;
}

#components-login {
    width: 300px;

}

#components-login .login-form {
    display: block;
    width: 300px;

}

#components-login .login-form-forgot {
    color: coral;
    float: right;
}


#components-login .goto-register {
    float: right;
}


#components-login .login-form-button {
    width: 100%;
}

#no-token-warning{
  display: none;

}

#legend {
    padding: 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    line-height: 18px;
    margin-bottom: 40px;

}

.legend-key {
    display: inline-block;
    border-radius: 20%;
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

.map-overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgba(27,27,27,0.8);
    margin-right: 20px;
    font-family: Arial, sans-serif;
    overflow: auto;
    border-radius: 3px;
}

.tooltip {
    position: absolute;
    margin: 8px;
    padding: 5px 10px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    max-width: 300px;
    font-size: 14px;
    z-index: 9;
    pointer-events: none;
}

.mapboxgl-control-container{
    color: black;
}

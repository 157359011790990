.controls {
    display: table-cell;
    width: 120px;
    vertical-align: middle;
}

[disabled]{
    cursor: not-allowed !important;
}
.timeline__control {
    height: 29px;
    width: 29px;
}

.timeline__control.btn {
    outline: none;
    background-color: transparent;
    margin: 0;
    border: 0;
    padding: 0;
    box-sizing: border-box;
    font-weight: 400;
    box-shadow: none;
    text-align: left;
    cursor: pointer;
    line-height: 20px;
    text-decoration: none;
    font-size: 0.85rem;
}

.timeline__control svg {
    height: 25px;
    width: 25px;
    fill: #fff;
}

.timeline__control.is-active svg {
    fill: #519ac2;
}
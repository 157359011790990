
.logo {
    background: url('../assets/images/logo.png');
    background-size: cover;
    width: 48px;
    height: 48px;
    margin-top: 8px;
    float: left;
}

#sidePanel{
    height: 100%;
}
.hide {
    display: none !important;
}

/*.cesium-widget-credits{*/
/*  display: none!important;*/
/*}*/

.cesium-credit-logoContainer, .cesium-credit-expand-link, .cesium-credit-delimiter {
    display: none !important;
}

.cesium-viewer-bottom {
    left: 20px !important;
}

/*.cesium-credit-textContainer>div:last-child{*/
/*  display: none!important;*/
/*}*/

span.cesium-sceneModePicker-wrapper {
    /* avoid sceneModePicker overlap by welcome info*/
    z-index: 1;
}

.ant-tree-show-line .ant-tree-switcher {
    background-color: #000000 !important;
}

.ant-select-item.ant-select-item-option, .ant-select-selection-item {
    color: white !important;
}

.ant-tree {
    color: white !important;
    font-weight: bold !important;
    font-size: 16px;
}

.ant-collapse-header {
    font-weight: bold !important;
    color: white !important;
}

.ant-input, .ant-input-affix-wrapper {
    border: 1px solid #c1bdbd;
}

div.title-bar {
    background: url("../assets/images/titlebar.png");
    background-size: auto;
    background-repeat: no-repeat;
    height: 92px;
    margin: 8px 0px 0px 10px;
}

.catalog-split{
    min-height: 100px!important;
}

.distance-legend {
    right: 20px !important;

}


div.welcome-info {
    position: absolute;
    z-index: 1;
    top: 45px;
    right: 0px;
}

rect.cesium-animation-knobInner {
    opacity: 0.3;
}

.cesium-viewer-fullscreenContainer {
    z-index: 3;
    padding-bottom: 2px;
}

.cesium-viewer-animationContainer, .cesium-viewer-timelineContainer, .cesium-viewer-fullscreenContainer {
    display: none;
}

.all-radar div.ant-col {
    border: solid cornsilk 0.5px !important;
    background-color: rgba(0, 0, 0, 0.5);
}

.ant-tabs-content {
    height: 100%;
}

.tab-pane {
    height: calc(100% - 80px);
    overflow-y: scroll;
}

.Resizer {
    background: #000;
    opacity: 1;
    z-index: 100;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.horizontal {

    height: 3px;
    background: #272727;
    margin: 3px 0;
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 2px solid rgba(255, 255, 255, 0.5);
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(0, 0, 0, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
    cursor: not-allowed;
}

.Resizer.disabled:hover {
    border-color: transparent;
}

/*
 * Created at 2020/4/27 下午4:13 by lhq
 */

.popup-show{
    display: block;
    width: 300px;
    max-width: 350px;
    height: 400px;
    background-color: #282c3499;
    position: absolute;
    -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    border-radius: 10px;
    border: 1px solid #cccccc55;
    min-width: 280px;
}

.popup-hide{
    display: none;
}


.popup-closer {
    cursor: pointer;
    z-index: 100;
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
}
.popup-closer:after {
    content: "✖";
}
.popover-content {
    min-width: 180px;
}

/* For demo */
.ant-carousel{
   color: white!important;
}


.index-rate{
    /*margin: 0 45px;*/
    float: right!important;
    margin-right: 30px;
    text-align: right;
}
.ant-rate-text{
    letter-spacing:4px;
    font-size: 16px!important;
}
.tab-like-dot button{
    height: 12px!important;
}

.tab-like-dot li{
    width: 30px!important;
    height: 12px!important;
    background-color: white;
}

.tab-like-dot button:hover{
    background-color: white!important;
}

.tab-like-dot .slick-active{
  background-color:red!important;
}

.radar-container1{
    padding: 2px;
}
.radar-container2{
    padding: 5px;

}
.cesium-popup-tip-container {
	width: 40px;
	height: 20px;
	position: absolute;
	left: 50%;
	margin-left: -20px;
	overflow: hidden;
	pointer-events: none;
	}
.cesium-popup-tip {
	width: 17px;
	height: 17px;
	padding: 1px;

	margin: -10px auto 0;

	-webkit-transform: rotate(45deg);
	   -moz-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg);
	}
.cesium-popup-content-wrapper,
.cesium-popup-tip {
	background: greenyellow;
	color: #333;
	box-shadow: 0 3px 14px rgba(0,0,0,0.4);
	}

/*.ant-layout.ant-layout-has-sider {*/
/*    background: url("../../assets/images/bg.jpg");*/
/*    background-size: cover;*/
/*    height: 100vh;*/
/*}*/

.ant-layout.profile-root{
       background: url("../../assets/images/bg.jpg");
    background-size: cover;
    height: 100vh;
}

.ant-layout.site-layout-background{
    background: #272727dd;
}

.ant-layout-header.header {
    display: flex;
    flex-direction: row;
}

.ant-layout.ant-layout-has-sider {
    height: calc(100vh - 64px);
}

.header-title {
    color: white;
    padding-left: 20px;
    font-size: 12pt;
    display: flex;
    justify-content: start;
    flex-shrink: inherit;
    font-weight: bold;
}

.welcome {
    color: white;
    font-size: medium;
    display: flex;
    flex: 1;
    justify-content: flex-end;
}
